import React,{useState} from 'react'
import Header from './apprLyout/Topnav';
import Sidenav from './apprLyout/Sidenav';
import Logo from '../assets/images/foodclogo.png'
import html2canvas from "html2canvas";
import {ReactComponent as DownloadIcon} from '../assets/images/download.svg'
import {ReactComponent as PrintIcon} from '../assets/images/print.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { jsPDF } from "jspdf";
import format from 'date-fns/format';
import { NumericFormat } from 'react-number-format'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios'
import ApprovalStatusImage from '../assets/images/image 12.png'
import Loader from '../components/Loader'
import {ReactComponent as ArrowLeft} from '../assets/images/ChevronLeft.svg'


export function convertDateFormat(dateString, inputFormat, outputFormat) {
  const date = new Date(dateString);
  
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }
  
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  
  return date.toLocaleDateString('en-GB', options);
}


const GrnPage = () => {
  const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
    const navigate=useNavigate()
    const [showModal,setShowModal]=useState(false)
    
    const createPDF = async () => {   
      const pdf = new jsPDF("portrait", "pt", "a4"); 

      const data = await html2canvas(document.querySelector("#pdf"));
      const img = data.toDataURL("image/png");  
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`GRN${receipt.grn.grn_number}`)
    };
    const print=()=>{
      navigate('/approval/pdf')
    }
    const receipt= JSON.parse(localStorage.getItem('receiptdata')) || [];
    const Colour =  '#027C94';
 
    console.log(receipt,'grn receipt')
    const [loading,setLoading]=useState(false)
    
   
    console.log(receipt,'receipt')
  // const deliveryLocation=receipt.line_items[0].purchase_order.metadata.ItemShipToLocation.Name
  
  const chickenConversion = receipt.invoice_line_items.some(item => 
    item.grn_line_item?.metadata?.total_weight_received !== undefined
);
const calculateTotalTaxAmount = () => {
    const totalTax = receipt.invoice_line_items.reduce((sum, item) => {
      return sum + (item.grn_line_item.tax_value || 0);
    }, 0);
  
    return totalTax;
  };
  function calculateProductTotal() {
    const lineItems = receipt.invoice_line_items || [];
    
    // Calculate the total gross value received
    const grossTotal = lineItems.reduce((total, lineItem) => {
      const grossValueReceived = parseFloat(lineItem.grn_line_item.gross_value_received);
      return total + grossValueReceived;
    }, 0);
  
    return grossTotal;
  }
  const check= receipt.invoice_line_items.some(item=>item?.grn_line_item?.extra_fields)
// const mailStatus=object.send_email?object.send_email:false


  return (
    <div>
    <Header />
    <div className="flex main-body">
      <div className="sidebar hidden md:block">
        <Sidenav />
      </div>
      <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3 mt-[70px]">
       
           <div className=" 
            flex justify-between  mb-4 text-sm text-[#545454] bg-white py-3 px-2">
  <button
  className="flex items-center justify-center gap-0"
  onClick={() => {
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath) {
      navigate(previousPath);
    }
  }}
>
  <ArrowLeft className="" /> <span className='text-blue-900 font-medium'>Goods Received Note</span>
</button>

            <div className='flex gap-2 md:gap-4'>
            <button className='flex items-center gap-2 text-sm font-normal' onClick={print}><PrintIcon/><span className='text-blue-900'>Print</span></button>
          <button className='flex items-center gap-2 text-sm font-normal' onClick={createPDF}><DownloadIcon/><span className='text-blue-900'>Download</span></button>
          </div>
            </div>
         
        
            <div className='bg-white  w-full  box-shadow' >
              <div id='pdf' className='bg-white w-full'>
            <div className=" items-center flex flex-col md:flex-row bg-white p-4">
                <div className="flex flex-col items-center">
                    <img className=' h-24 md:h-32' src={Logo} alt="" />
                    <p className='font-semibold text-lg md:text-2xl -mt-8 text-[#2957A4]'>Food Concepts</p>
                </div>
                <div className="flex flex-col items-center self-center justify-center">
                    <p className='font-semibold text-xl text-center m:text-3xl  text-blue-800 md:ml-16 my-2 md:my-4'>Food Concepts (Chicken Republic)</p>
                    <p className='text-[#474646] font-normal text-center text-base md:ml-16'><span className='underline  md:pb-3'>2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria </span>Tel: +234 (0) 809 016 5972 | +234 (0) 809 016 5973</p>
                </div>
      
            </div>
            <div className="flex flex-col md:flex-row justify-between md:items-center text-[#545454] text-base p-4">
      <ul className="m-0 p-0 justify-end text-sm">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>{receipt.vendor.vendor_name}</span>
  </li>
  <li className='flex gap-6 '>
    <span className='flex'>Store Name:</span>
    <span className=''>{receipt.grn.store.metadata.store_name}</span>
    {/* <span className='text-justify'>{deliveryLocation}</span> */}
    
  </li>
</ul>
{/**
 * {
    "message": "GRN Created",
    "data": {
        "id": 48,
        "purchase_order": 4,
        "store": 1,
        "grn_number": 255166,
        "received_date": "2024-03-14",
        "line_items": [
            {
                "id": 31,
                "grn": 48,
                "purchase_order_line_item": 14,
                "quantity_received": "40.000"
            }
        ]
    },
    "status": "success"
}
 */}
        <div className='flex flex-col'>
        <div className="md:text-right text-[#5B5B5B] font-normal text-center text-xl my-2">Goods Reciept Note</div>
        <div className="border border-gray-200 font-normal flex text-sm flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow "> {format(new Date(receipt?.grn?.created), 'dd-MMM-yyyy')}</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>Purchase Order Number</span>
            <span className="text-right flex-grow ">{receipt?.grn.purchase_order.po_id}</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">{receipt.grn.grn_number}</span>
          </div>
       
          
        </div>
        </div>
     
      </div>

      <section class="bg-gray-50 ">
    <div class="mx-auto ">
      
        <div class="bg-white  relative  overflow-hidden px-4">
            
            <div class="overflow-x-auto ">
            <div className="bg-white  md:w-full w-full  mx-auto ">
            <table className="md:w-full w-[1024px]">
      <thead
        className=" text-white text-sm w-full bg-blue-700"
      
      >
        <tr>
          <td className="text-sm font-medium py-2  px-2">SN</td>
          <td className="text-sm font-medium px-2">Description</td>
          <td className="text-sm font-medium px-2">Product Code</td>
          <td className="text-sm font-medium  px-2">UoM</td>
          <td className="text-sm font-medium  text-right">Qty Received</td>
          
          {/* <td className="text-sm font-medium text-right px-2">Qty Outstanding</td> */}
          {chickenConversion && <td className="  py-3 font-normal px-2 text-right " >Pieces Received</td>}
          
          <td className="text-sm font-medium text-right py-2 px-2">
            Unit Price
          </td>
      
           <td className="text-sm font-medium text-right  px-2">
            Tax Amount
          </td>
          <td className="text-sm font-medium text-right ">Discount</td>
          <td className="text-sm font-medium text-right  px-2">
            Total Price
          </td>
      
            
       
        </tr>
      </thead>
      <tbody>
        {receipt.invoice_line_items.map((item, index) => (
          <tr key={index} className="border-b dark:border-gray-700 text-sm">
            <td className="text-sm pl-2 py-3 px-2">{index + 1}</td>
            <td className="pl-8 text-sm px-2">{item?.grn_line_item?.purchase_order_line_item?.product_name|| ''}</td>
            <td className="pl-8 text-sm px-2">{item?.grn_line_item?.purchase_order_line_item?.metadata.ProductID||''}</td>
            <td className=" text-sm px-2">{item?.grn_line_item?.purchase_order_line_item?.metadata?.ListUnitPriceBaseUnitCode
||''}</td>
            <td className=" text-sm  text-right px-2">
              <NumericFormat value={item.quantity ? item?.grn_line_item?.quantity_received : item?.grn_line_item.quantity_received} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
            {/* <td className=" text-sm  text-right px-2">
            <NumericFormat value={ item?.grn_line_item?.purchase_order_line_item?.delivery_outstanding_quantity||''} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td> */}
            {chickenConversion &&  (item.grn_line_item.metadata.total_pieces_received? <td className="text-right px-2 py-3"><NumericFormat value={item.grn_line_item.metadata.total_pieces_received}displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                                
                                </td>: <td className='text-right pr-6  py-3'>NA</td> )}
            <td className="text-sm  text-right px-2">
              {/* ₦<NumericFormat value={item.grn_line_item.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /> */}
              ₦<NumericFormat value={item?.grn_line_item?.purchase_order_line_item?.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
          
            
              <td className="text-sm px-2 text-right ">
              ₦<NumericFormat value={item.grn_line_item.tax_value} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td> 
            <td className="text-sm px-2 text-right">{item.discount ? item.discount : 0.00}</td>
            <td className="text-sm px-2 text-right ">
              ₦<NumericFormat value={item.grn_line_item.net_value_received
} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
          </tr>
        ))}
                                                           <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      {/* <td></td> */}
      <td></td>
      <td className="   py-3 " >Sub Total</td>
      <td className="text-right pr-2   py-3 ">  <NumericFormat value={calculateProductTotal()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
    <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      {/* <td></td> */}
      <td></td>
      <td className="pr-4  py-3 " >Total Tax </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={calculateTotalTaxAmount()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
     
                                                   <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      {/* <td></td> */}
      <td></td>
      <td className="  pr-4 py-3 " >Total </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={(calculateProductTotal()+calculateTotalTaxAmount())} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
      </tbody>
    </table>
      

</div>
            </div>
           
        </div>
    </div>
    </section>
                        </div>    
                                                  
      </div>
    


{loading&&<Loader/>}
      
        </div>
        </div>
    </div>
  )
}

export default GrnPage
