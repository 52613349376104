import React, { useEffect, useState } from 'react';
import TableCommponnent from '../../components/TableCommponnent';
import axios from 'axios';
import Header from '../apprLyout/Topnav';
import Loader from '../../components/Loader';
import { useNavigate, useLocation} from 'react-router-dom';
import Sidenav from '../apprLyout/Sidenav';
import OtherPageTitle from '../../components/otherpagesTitle'; 
import SessionExpired from './sessionExpired'
const Approved = () => {
  const token = localStorage.getItem('accessToken');
  const [invoiceData, setInvoiceData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sessionExpired, setsessionExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleButtonClick=()=>{
    console.log('itemID')
  }
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(1);
  const [count, setCount] = useState(1);
  const handlePreviousPage = () => {
    navigate('/approval/home');
  };
  const location=useLocation()
  const totalPages = Math.ceil(invoiceData?.length / itemsPerPage);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    const pageSizeParam = params.get("page_size");

    const newPage = pageParam ? Number(pageParam) : 1;
    const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

    setPage(newPage);
    setSize(newPageSize);

    getSignables(newPage, newPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const getSignables = async () => {
    setLoading(true);

    try {
      const roles = JSON.parse(localStorage.getItem('roles')) || [];
      const isAdmin = roles.includes("dmd_ss");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/${isAdmin ? 'completed' : 'all?approved=1'}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data.data, "I am the res");

      // Filter the invoices based on completed and approved status
      const approvedInvoices = response?.data?.data?.results?.filter(
        (invoice) => invoice?.workflow?.completed === true && invoice?.workflow?.approved === true
      );

      localStorage.setItem('responseData', JSON.stringify(response.data.data.results));
      if(isAdmin){
        setInvoiceData(approvedInvoices);
      setCount(approvedInvoices.length)
      const totalPages=Math.ceil(approvedInvoices.length/size)
      setTotal(totalPages)
      }
      else{setInvoiceData(response.data.data.results);
        setCount(response.data.data.count)
        const totalPages=Math.ceil(approvedInvoices.length/size)
        setTotal(totalPages)
      }
      
     
    } catch (error) {
      console.log(error, "PO fetching error.");
      const errorMessage =
        error?.response?.data?.detail || 'Error Fetching PO Items';
      if (error?.response?.status === 401) {
        setsessionExpired(true);
      } else {
        setError(errorMessage);
      }
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleChangeItemsPerPage = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = invoiceData?.slice(startIndex, endIndex);

  return (
    <>
    {sessionExpired ? <SessionExpired/>
    :
    <div>
    <Header />
    <div className="flex main-body">
      <div className=" hidden md:block">
        <Sidenav />
      </div>
      <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3 mt-[70px]">
        {loading ? (
          <Loader />
        ): (
          <>
            <OtherPageTitle title='Approved Invoices' action={handlePreviousPage} />
            <TableCommponnent 
      data={currentData}
      recent_invoice={false}
      currentPage={currentPage}
      handleChangeItemsPerPage={handleChangeItemsPerPage}
      itemsPerPage={itemsPerPage}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      totalPages={totalPages}
      onButtonClick={handleButtonClick}
      complete={true}
      total={total}
      count={count}
      size={size}
      page={page}
      
      />
          </>
        )}
      </div>
    </div>
  </div>}
    </>

  );
};

export default Approved;
