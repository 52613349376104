import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import Error from "../components/Error";
import {
  FetchPurchaseOrderRequest,
} from "../redux/constants/PurchaseOrderConstant";
import axios from "axios";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import PageTitle from "../components/PageTitle";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, TextField } from "@mui/material";
import {ReactComponent as Pallm} from '../assets/images/waavingpalm.svg'
import { Modal } from "react-bootstrap";
//import CollapsibleTable from "../components/InvoiceCreationStep2";
import CollapsibleTable from "../components/Step2Invoice";
import CreateInvoiceStep4 from "../components/CreateInvoiceStep4";
import InvoiceCreationStep5 from "./InvoiceCreationStep5";
// import Alert from "@mui/material";
import Loader from "../components/Loader";

const CreateInvoice = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const [GRN, setGRN] = useState([]);
  const [table, setTable] = useState([]);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(4);

  const userLogin = JSON.parse(localStorage.getItem('userInfo'));
  const [pageData, setPageData] = useState([]);
  
const token = localStorage.getItem('access')

  const vendorInformation = userLogin?.vendor_name||""
  const [loadinng, setLoadinng] = useState(false);

console.log(table,'taaa')
useEffect(() => {
  if (!token) {
    navigate("/sign-in");
    return;
  }

  fetchData(token);

  // Check if it's the first sign-in
  const firstSignIn = localStorage.getItem("firstSignIn") === "true";
  if (firstSignIn) {
    setShowSettingModal(true);
  }
}, [token, navigate]);

  const fetchData = async (token) => {
    try {setLoadinng(true)
      console.log()
      dispatch({ type: FetchPurchaseOrderRequest });
     //  https://20.101.63.100/egrn/v1/vendors/search?email=sunil.kriplani@globusresources.com
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/purchaseorders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data.BusinessPartner.BusinessPartnerFormattedName, "res");
      // setVendorInfoinformation(response.data.data.BusinessPartner.BusinessPartnerFormattedName)
      if(response.data.data){
        setTable(response.data.data);
        console.log(table,'data')
      }
      else{
        Swal.fire(
          {
              title: "Not Found",
              text: 'No GRN created, kindly proceed to deliver orders before creating invoice',
              icon: "error",
            }
        )
      }
     
      // localStorage.setItem('vendorname', response.data.data.BusinessPartner.BusinessPartnerFormattedName);
      //   dispatch({ type: FetchPurchaseOrderSuccess, payload: response.data.PurchaseOrders

      
      //   });
      //   setTable(response.data.PurchaseOrders);
     
    } catch (error) {
      //   dispatch({
      //     type: FetchPurchaseOrderFailure,
      //     payload: error.response && error.response.data.PurchaseOrders
      //       ? error.response.data.PurchaseOrders
      //       : error.message,
      //   });
      navigate('/sign-in')
    }
    finally{
      setLoadinng(false)
    }
  };

  const getGRN = async () => {
    localStorage.removeItem('SelectedItems');
    localStorage.removeItem('selectedRows');
    localStorage.removeItem('ReferenceNumber');
   setLoadinng(true);
    // setShowVendorSearchButton(false); // Hide the search button
    // setLoading(true); // Show loading indicator

    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_BASE_URL}/egrn/v1/purchaseorders/${selectedPO.po_id}/grns`
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/grns?po_id=${selectedPO.po_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "I am the res");
        // const updatedDatas = response.data.data.map(item => ({
        //   purchase_order: item.purchase_order,
        //   store: item.store,
        //   created: item.created,
        //   total_value_received: item.total_value_received,
        //   grn_number: item.grn_number,
        //   line_items: item.line_items.map((lineItem) => ({
        //     ...lineItem,
        //     discountStatus: item.discountStatus,
        //     taxStatus: item.taxStatus
        //   })) // Close the inner map function properly
        // }));
      
        //setGRN(updatedDatas);
        setGRN(response.data.data);
        Swal.close();
        handleNextPage();
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError(error.response?.data.message)
        setShowError(true)
        // Swal.fire({
        //   title: error.response.statusText,
        //   text: error.response.data.detail,
        //   icon: "error",
        // });
        //toast.error(error.response.data.message);
      } finally {
        setLoadinng(false);
      }
      
  };
  const handleCloseSettingModal = () => {
    setShowSettingModal(false);
    
  };
  const navigateProfileSetting = () => {
    // Close the setting modal
    handleCloseSettingModal();
   navigate('/profile')
    // Set "firstSignIn" to false in localStorage
  
  };
  
  const [selectedPO, setselectedPO] = useState(null);
  // console.log(table.data);
  console.log(selectedPO);
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const filteredData = table
    ? table.filter((item) => item.Item.length > 0)
    : [];
  console.log(filteredData);
  useEffect(() => {
      const fetchData = async () => {
        setLoadinng(true);
        try {
          
const token = localStorage.getItem('access')
; // Assuming token is stored in localStorage
  
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
  
          // Handle the response data here
          console.log(response.data.data,'info');
          localStorage.setItem('vendorDetails',JSON.stringify(response.data.data))
        } catch (error) {
          console.error('Error:', error.message);
          localStorage.clear()
          navigate('/sign-in')
          // Handle errors as needed
        } finally {
          setLoadinng(false);
        }
      };
  
      fetchData();
    }
  , []);
  // Empty dependency array to run only once when the component mounts
  return (
    <div>
      <Header />
      <div className="flex main-body ">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="content p-3 md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%]">
          <PageTitle pagetitle="Create Invoice" />
         
          <Modal
            show={showSettingModal}
            onHide={handleCloseSettingModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex align-items-center flex-column justify-content-center">
              <Pallm/>
                <div className="approval-status-message text-center">
                
                  <div className="text-sm">Get started!</div>
                  <div className="text-base font-bold" >Welcome to VIMP</div>
                  <div className="text-sm">To get started, click the button below to customize your invoice settings</div>
                </div>
                <div className="flex gap-3 items-center text-sm justify-center">
                <button
                  className="my-2 bg-white text-blue-700 border-2 border-blue-700 py-2 px-4 rounded-md hover:bg-green-700"
                  onClick={handleCloseSettingModal}
                >
                  Skip
                </button>
                <button
                  className="my-2 bg-blue-600 text-white border-2 py-2 px-4 rounded-md hover:bg-blue-700"
                  onClick={navigateProfileSetting}
                >
                  Customize Invoice Settings
                </button>
                </div>
               
              </div>
            </Modal.Body>
          </Modal>
          {/* Regular Modal */}
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Body>{/* Modal Content */}</Modal.Body>
          </Modal>
          {loadinng && <Loader/>}
          {error &&
      <Error show={showError} message={error} handleShow={()=>{setShowError(!showError)}}/>
      }
          {currentPage === 1 && (
            <div className="pb-3 bg-white  flex flex-col box-shadow my-3">
              <div className="content-container-title bg-blue-800 flex justify-between text-base items-center py-2 px-4 ">
                <span>Purchase Order</span>
                <span>
                  Step {currentPage} of {totalPages}
                </span>
              </div>
              <div className="px-4 my-4">
                <p className="text-base">
                  Select a purchase order yet to be invoiced
                </p>
                <form className="flex flex-col md:flex-row justify-between mt-2 md:mt-3 gap-3 md:gap-10">
      <div className="w-full flex flex-col md:flex-row md:items-center ">
        <label htmlFor="vendor" className=" mr-4 text-sm mb-2 md:mb-0">
          Vendor:
        </label>
        <input
                      type="text"
                      id="vendor"
                      value={vendorInformation}
                      className="mt-1 block  p-2 username-input md:w-[80%] shadow-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none"
                    />
      </div>
      <div className="w-full flex flex-col md:flex-row md:items-center">
        <label htmlFor="poOrderID" className="text-sm w-24 md:w-36 mr-3 mb-2 md:mb-0">
          PO Number:
        </label>
        <Autocomplete
          id="combo-box-demo"
          options={table}
          getOptionLabel={(option) => option.po_id}
          value={selectedPO}
          size="small"
          onChange={(event, newValue) => setselectedPO(newValue)}
          className="w-full"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                style: {
                  backgroundColor: "#fff",
                  height: "42px",
                  borderRadius: "0.35rem",
                },
              }}
            />
          )}
        />
      </div>
    </form>
              </div>
              <button
  className={`md:self-end self-center mb-3 px-4 py-2 w-[87%] md:w-36 mx-4 md:mx-0 border text-sm font-medium rounded-md transition-colors duration-300 ${
    !selectedPO
      ? "bg-gray-400 text-gray-800 cursor-not-allowed"
      : "bg-blue-500 text-white hover:bg-blue-700"
  }`}
  disabled={!selectedPO} // Disable button if no item is selected
  onClick={getGRN}
>
  View GRN
</button>
            </div>
          )}
          {currentPage === 2 && (
            <div className="my-3">
              <CollapsibleTable
                handlePrevious={handlePrevPage}
                totalPages={totalPages}
                currentPage={currentPage}
                vendorInfo={vendorInformation}
                selectedGRN={GRN}
                selectedPO={selectedPO.po_id}
                handleNext={handleNextPage}
              />
            </div>
          )}
          {/* {currentPage === 3 && (
            <div className="my-3">
              <InvoiceCreationStep3
                handlePrevious={handlePrevPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handleNext={handleNextPage}
              />
            </div>
          )} */}
          {currentPage === 3 && (
            <div className="my-3">
              <CreateInvoiceStep4
                 handlePrevious={handlePrevPage}
                 currentPage={currentPage}
                 totalPages={totalPages}
                 handleNext={handleNextPage}
                 selectedPO={selectedPO.po_id}
                 vendorInfo={vendorInformation}
              />
            </div>
          )}
          {currentPage === 4 && (
            <div className="my-3">
              <InvoiceCreationStep5
                handlePrevious={handlePrevPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handleNext={handleNextPage}
                selectedPO={selectedPO.po_id}
                vendorName={vendorInformation}
              />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateInvoice;