import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationPages from '../components/AuthenticationPages';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import ApprovalStatusImage from '../assets/images/image 12.png';
import { ReactComponent as EyeIcon } from '../assets/images/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../assets/images/invisible.svg';
import AuthSpinner from '../components/Authspinner';
import axios from 'axios';
import ErrorImage from '../assets/images/errorimage.png';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  useEffect(() => {
    console.log('Token from URL:', token);
  }, [token]);

  // Password validation function
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Weak Password!');
      setShowSuggestions(true);
      return;
    }

    setLoading(true);
    setError('');
    setPasswordError('');
    setShowSuggestions(false);

    try {
      const passwordCreationResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/password-reset/confirm`, {
        token,
        email,
        new_password: password,
      });

      setModalMessage('Password creation successful');
      setShowModal(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred, try again later';
      setError(errorMessage);
      setPasswordError(errorMessage);
      setModalMessage(errorMessage);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setModalMessage('');
    setError('');
    setPasswordError('');
    setShowSuggestions(false);
    if (!error) {
      navigate('/sign-in');
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <div className='d-flex align-items-center flex-column justify-content-center'>
            {error ? (
              <img src={ErrorImage} alt='Error' className='error-image h-16' />
            ) : (
              <img src={ApprovalStatusImage} alt='Approval Status' />
            )}
            <div className={error ? 'verify-error' : ''}>
              {error ? 'Oops!' : ''} 
            </div>
            {modalMessage && (
              <div className={`approval-status-message text-center ${error ? 'text-red-600' : 'text-green-600'}`}>
                {modalMessage}
              </div>
            )}
            <Button
              className={`my-2 ${error ? 'bg-red-500 text-white' : 'bg-green-600 text-white'} py-2 px-4 rounded-md hover:bg-${error ? 'red-600' : 'green-700'}`}
              onClick={handleClose}
            >
              {error ? 'Close' : 'Ok'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AuthenticationPages
        FormTitle='Reset Password'
        buttontext='Create Password'
        formaction={handleSubmit}
        PageTitle='Create Password'
        loading={loading}
      >
        <Form.Group>
          <div className="my-3 font-bold text-lg leading-11 tracking-normal text-left underline">
            Step 2 of 2: Reset Password
          </div>
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
                setShowSuggestions(false);
              }}
              required
              className="row-container2 appearance-none border-gray-500 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-2 focus:border-[#115FF5]"
            />
            <InputGroup.Text onClick={() => setShowPassword(!showPassword)} className="border-gray-900 focus:outline-none">
              {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label className='mt-3'>Confirm Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError(false);
                setPasswordError(false);
                setShowSuggestions(false);
              }}
              required
              className="row-container2 appearance-none border-gray-500 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-2 focus:border-[#115FF5]"
            />
            <InputGroup.Text onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="border-gray-900 focus:outline-none">
              {showConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        {passwordError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
            <span>{passwordError}</span>
          </div>
        )}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
            <span>{error}</span>
          </div>
        )}

        {showSuggestions && (
          <div className="mt-2 text-red-600 text-sm">
            <p>Suggestions for a strong password:</p>
            <ul className="list-disc ml-5">
              <li>At least 8 characters long</li>
              <li>Contains both uppercase and lowercase letters</li>
              <li>Includes at least one number</li>
              <li>Has at least one special character (e.g., !, @, #, $, %)</li>
            </ul>
          </div>
        )}

        {/* <Button
          className={`authentication-button my-3 py-2 ${loading ? 'd-flex align-items-center justify-content-center gap-1 ' : ''}`}
          type='submit'
          onClick={handleSubmit}
        >
          {loading && <AuthSpinner />} <span>{loading ? '' : 'Create Password'}</span>
        </Button> */}
        <Button
  className={`authentication-button my-3 py-2 ${loading ? 'd-flex align-items-center justify-content-center gap-1' : ''} ${
    error ? 'cursor-not-allowed opacity-50' : ''
  }`}
  type="submit"
  onClick={handleSubmit}
  disabled={!!error || loading} // Disable if there's an error or while loading
>
  {loading && <AuthSpinner />} <span>{loading ? '' : 'Create Password'}</span>
</Button>

      </AuthenticationPages>
    </div>
  );
};

export default ResetPassword;
